import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Layout, LocaleContext, Seo } from "../components";
import { localeRepositoryInstance } from "../i18n/i18n";

const HelpPage = (props) => (
  <Layout {...props}>
    <Seo location="help" title="seoHelpPageTitle" />
    <Container fluid="lg" className="py-4 py-md-5">
      <LocaleContext.Consumer>
        {({ fullLocaleCode }) => {
          const urlBase =
            localeRepositoryInstance.getUrlBaseByLocaleCode(fullLocaleCode);
          return (
            <>
              <h1 className="mb-4">
                <FormattedMessage
                  id="helpTitle"
                  defaultMessage="Help – Get Answers to Your Questions"
                />
              </h1>
              <Row>
                <Col lg="4" className="d-flex flex-column mb-2">
                  <Card className="shadow-sm flex-fill">
                    <Link to={`/${urlBase}/articles/printing-guide`}>
                      <Card.Body>
                        <Card.Title className="font-alt-title text-secondary mb-3">
                          <FormattedMessage
                            id="helpArticleHowDoIPrintBookletTitle"
                            defaultMessage="How do I print the small booklet?"
                          />
                        </Card.Title>
                        <p className="text-dark">
                          <FormattedMessage
                            id="helpArticleHowDoIPrintBookletDescription"
                            defaultMessage="So, you got the small booklet size, and now you're wondering how you can print it. No worries, we're here to help!"
                          />
                        </p>
                      </Card.Body>
                    </Link>
                  </Card>
                </Col>

                <Col lg="4" className="d-flex flex-column mb-2">
                  <Card className="shadow-sm flex-fill">
                    <Link to={`/${urlBase}/articles/translation-guide`}>
                      <Card.Body>
                        <Card.Title className="font-alt-title text-secondary mb-3">
                          <FormattedMessage
                            id="helpArticleHowDoITranslateTitle"
                            defaultMessage="How do I translate YearCompass?"
                          />
                        </Card.Title>
                        <p className="text-dark">
                          <FormattedMessage
                            id="helpArticleHowDoITranslateDescription"
                            defaultMessage="Found a mistake in an existing translation? Want to bring YearCompass to a whole new language? Either way, you've come to the right place!"
                          />
                        </p>
                      </Card.Body>
                    </Link>
                  </Card>
                </Col>

                <Col lg="4" className="d-flex flex-column mb-2">
                  <Card className="shadow-sm flex-fill">
                    <Card.Body>
                      <Card.Title className="font-alt-title text-secondary mb-3">
                        <FormattedMessage
                          id="helpArticleOtherQuestionsTitle"
                          defaultMessage="I have other questions"
                        />
                      </Card.Title>
                      <p className="text-dark">
                        <FormattedMessage
                          id="helpArticleOtherQuestionsDescription"
                          defaultMessage="If your question is still unanswered, let us know by writing an email to <a>hello@yearcompass.com</a>, and we'll figure it out together."
                          values={{
                            a: (chunks) => (
                              <a href="mailto:hello@yearcompass.com">
                                {chunks}
                              </a>
                            ),
                          }}
                        />
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          );
        }}
      </LocaleContext.Consumer>
    </Container>
  </Layout>
);

export default HelpPage;
